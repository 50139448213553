import React, { useEffect, useState } from 'react';
import './styles/Topbar.css';
import darkImage from '../assets/images/wb-logo-dark.png';
import lightImage from '../assets/images/wb-logo-light.png';

const Topbar = ({ style }) => {
    const [menu, setMenu] = useState(false); // false is closed and true is open
    const [dropdown, setDropdown] = useState(false);
    const navElements = [
        { name: 'Data', url: 'dashpage' },
        { name: 'Map', url: 'map' },
        { name: 'Education', url: 'education' },
        { name: 'AI', url: 'ai' },
    ];
    const educationDropdown = [
        { name: 'Educator Resources', url: 'Educator Resources' },
        { name: 'Art & Science', url: 'Art & Science' },
        { name: 'Pre-k - 3rd Grade', url: 'Prek' },
        { name: '4th - 8th Grade', url: 'Fourth' },
        { name: '9th - 12th Grade', url: 'Ninth' },
        { name: 'Collegiate', url: 'Collegiate' },
    ];
    let background, textColor, burgerColor;
    if (style !== 'transparent') {
        background = 'rgb(231, 235, 239, 0.6)';
        textColor = 'black';
        burgerColor = 'black';
    }
    useEffect(() => {
        var topLine = document.querySelector('.top-line');
        var middleLine = document.querySelector('.middle-line');
        var bottomLine = document.querySelector('.bottom-line');
        if (menu) {
            topLine.style.transform = 'rotate(45deg)';
            topLine.style.right = '-2px';
            topLine.style.top = '7px';
            topLine.style.transition = 'all 0.4s';
            middleLine.style.opacity = '0';
            bottomLine.style.transform = 'rotate(-45deg)';
            bottomLine.style.top = '2px';
            bottomLine.style.right = '-2px';
            bottomLine.style.transition = 'all 0.4s';
        } else {
            topLine.style.transform = 'rotate(0deg)';
            topLine.style.right = '0px';
            topLine.style.top = '0px';
            topLine.style.transition = 'all 0.4s';
            middleLine.style.opacity = '1';
            bottomLine.style.transform = 'rotate(0deg)';
            bottomLine.style.top = '14px';
            bottomLine.style.right = '0px';
            bottomLine.style.transition = 'all 0.4s';
        }
    }, [menu]);

    const updateDropdownLeave = (e) => {
        setDropdown(false);
    };
    const updateDropdownEnter = (e) => {
        if (e?.target?.id === 'Education') {
            setDropdown(true);
        } else {
            setDropdown(false);
        }
    };
    return (
        <div className="nav-bar">
            <div
                className="topbar"
                style={{
                    backgroundColor: background || 'rgba(6, 95, 146, 0.3)',
                    color: textColor || 'white',
                }}>
                <a href="/">
                    <img width="200px" height="52px" alt="wahoo-bay" src={background ? darkImage : lightImage} />
                </a>
                {/* Desktop Navbar */}
                <div className="nav-content desktop">
                    {navElements.map((element, index) => (
                        <div key={index}>
                            <div
                                onMouseEnter={(e) => updateDropdownEnter(e)}
                                onMouseLeave={(e) => updateDropdownLeave(e)}
                                id={element?.name}
                                style={{
                                    fontSize: 18,
                                    fontWeight: 'bold',
                                    margin: 0,
                                    marginRight: 6,
                                    padding: '0.8rem 0.5rem 1.1rem 0.5rem',
                                }}>
                                <a
                                    id={element?.name}
                                    className="nav-element"
                                    style={{ color: textColor || 'white' }}
                                    href={`/pages/${element?.url}`}>
                                    {element?.name} {element?.name === 'Education' && 'v'}
                                </a>
                                {dropdown && (
                                    <div
                                        className={`${element?.name}-dropdown`}
                                        style={{
                                            backgroundColor: background || 'rgba(6, 95, 146, 0.3)',
                                            color: textColor || 'white',
                                        }}>
                                        {educationDropdown.map((tab, index) => (
                                            <a
                                                className="nav-element"
                                                id={element?.name}
                                                key={index}
                                                style={{
                                                    color: textColor || 'white',
                                                    padding: '0rem 0.5rem',
                                                    fontSize: '14px',
                                                }}
                                                href={`/pages/education/${tab?.url}`}>
                                                {tab?.name}
                                            </a>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="nav-content mobile lines" onClick={(e) => setMenu(!menu)}>
                    <span className="line top-line" style={{ backgroundColor: burgerColor || 'white' }}></span>
                    <span className="line middle-line" style={{ backgroundColor: burgerColor || 'white' }}></span>
                    <span className="line bottom-line" style={{ backgroundColor: burgerColor || 'white' }}></span>
                </div>
                {/* Mobile Navbar */}
                {menu && (
                    <div
                        className="mobile-menu"
                        style={{
                            backgroundColor: background || 'rgba(6, 95, 146, 0.3)',
                            color: textColor || 'white',
                        }}>
                        {navElements.map((element, index) => (
                            <a href={`/pages/${element?.url}`} key={index}>
                                <h4 className="nav-element data" style={{ color: textColor || 'white' }}>
                                    {element?.name}
                                </h4>
                            </a>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Topbar;
