import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'esri-leaflet';
import IconButton from '@mui/material/IconButton';
import NavigationIcon from '@mui/icons-material/Navigation';
import BasemapToggleIcon from '@mui/icons-material/Map';
import StyledButton from './StyledButton';

const config = {
    API_URL: 'https://backend.wahoobay.net',
};

const Map = () => {
    const [sensor1Info, setSensor1Info] = useState(null);
    const [sensor3Info, setSensor3Info] = useState(null); // Water Sensor
    const [currentCoord, setCurrentCoord] = useState(0);
    const [basemap, setBasemap] = useState('satellite'); // 'satellite' or 'osm'

    useEffect(() => {
        fetchData(`${config.API_URL}/api/v1/sitedata/latest?site=1`)
            .then((data) => {
                console.log('Data for site 1:', data);
                setSensor1Info(data?.readings);
            })
            .catch((error) => {
                console.error('Error fetching data for site 1:', error);
            });

        // Underwater Sensor Data
        fetchData(`${config.API_URL}/api/v1/sitedata/latest?site=3`) //site url 3
            .then((data) => {
                // Process data for site 3
                // console.log('Data for site 3:', data?.readings);
                setSensor3Info(data?.readings);
            })
            .catch((error) => {
                console.error('Error fetching data for site 3:', error);
            });
    }, []);

    const fetchData = async (url) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    };

    const coordinates = [
        {
            longitude: -80.08335,
            latitude: 26.2603,
            color: 'red',
            title: 'Wahoo Bay',
        },
        {
            longitude: -80.0873,
            latitude: 26.2351,
            color: 'yellow',
            title: 'Pompano Fishing Pier',
        },
    ];

    const markerLocations = [
        ...coordinates,
        {
            longitude: -80.0865,
            latitude: 26.235,
            title: 'Live Feed',
            color: 'orange',
            videoEmbed: `<iframe width="300" height="315" src="https://www.youtube.com/embed/hFr5w9KLEA4?si=8rGG-G05lw8TcFZP&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
        },
    ];

    const switchMarker = () => {
        const nextCoord = (currentCoord + 1) % coordinates.length;
        setCurrentCoord(nextCoord);
    };

    const toggleBasemap = () => {
        setBasemap(basemap === 'satellite' ? 'osm' : 'satellite');
    };

    const esriBasemapUrl = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer';
    const osmBasemapUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

    const MapCenter = () => {
        const map = useMap();
        useEffect(() => {
            const { latitude, longitude } = coordinates[currentCoord];
            map.setView([latitude, longitude], map.getZoom());
        }, [map]);
        return null;
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                position: 'relative',
            }}>
            <div style={{ zIndex: 1, position: 'relative', width: '80%', height: '80%' }}>
                <MapContainer
                    center={[26.25955, -80.0828]}
                    zoom={16}
                    style={{ height: '100%', width: '100%' }}
                    maxZoom={18}
                    minZoom={16}
                    dragging={false} // Disable dragging
                >
                    <TileLayer url={basemap === 'satellite' ? `${esriBasemapUrl}/tile/{z}/{y}/{x}` : osmBasemapUrl} />
                    {markerLocations.map((location, index) => {
                        return (
                            <Marker
                                key={index}
                                position={[location.latitude, location.longitude]}
                                icon={L.icon({
                                    iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-${
                                        location.color || (index === 2 ? 'orange' : index === 0 ? 'red' : 'yellow')
                                    }.png`,
                                    shadowUrl:
                                        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                                    iconSize: [25, 41],
                                    iconAnchor: [12, 41],
                                    popupAnchor: [1, -34],
                                    shadowSize: [41, 41],
                                })}>
                                <Popup>
                                    {location.color === 'orange' ? (
                                        <div>
                                            <div dangerouslySetInnerHTML={{ __html: location.videoEmbed }} />
                                            <StyledButton title="Fish Identification Page" url="/pages/ai" />
                                        </div>
                                    ) : location.color === 'red' ? (
                                        sensor1Info ? (
                                            <div>
                                                <div>{location.title}</div>
                                                <div>
                                                    Air Temperature: {sensor1Info ? sensor1Info[7] : 'Loading...'}° C
                                                </div>
                                                <div>
                                                    Water Level:{' '}
                                                    {sensor1Info ? sensor1Info[18].toFixed(1) : 'Loading...'} meters
                                                </div>
                                                <div>
                                                    Average Wind Speed today:{' '}
                                                    {sensor1Info ? sensor1Info[5] : 'Loading...'} km/h
                                                </div>
                                                <a
                                                    href="https://beta.wahoobay.net/pages/dashpage"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    View Dashboard
                                                </a>
                                            </div>
                                        ) : (
                                            <div>Under Maintanance</div>
                                        )
                                    ) : sensor3Info ? (
                                        <div>
                                            <div>{location.title}</div>
                                            <div>Water Temperature: {sensor3Info && Math.round(sensor3Info[0])}°C</div>
                                            <div>Water Level: {sensor1Info && sensor1Info[18].toFixed(1)} meters</div>
                                            <a
                                                href="https://beta.wahoobay.net/pages/dashpage"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                View Dashboard
                                            </a>
                                        </div>
                                    ) : (
                                        <div>Under Maintanance</div>
                                    )}
                                </Popup>
                            </Marker>
                        );
                    })}
                    <MapCenter />
                </MapContainer>
                <div style={{ position: 'absolute', top: '10px', right: '1%', zIndex: 400 }}>
                    <IconButton onClick={switchMarker}>
                        <NavigationIcon style={{ color: 'white' }} />
                    </IconButton>
                </div>
                <div style={{ position: 'absolute', top: '50px', right: '1%', zIndex: 400 }}>
                    <IconButton onClick={toggleBasemap}>
                        <BasemapToggleIcon style={{ color: 'white' }} />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default Map;
