import React from 'react';
import './ComponentsCSS/NewsContactUs.css';

const contactUsData = [
    { title: 'Phone', content: '<div className="contact-individual-content">954.786.4607</div>' },
    {
        title: 'Wahoo Bay Park Address',
        content:
            '<div className="contact-individual-content">2700 North Ocean Blvd,<br /> Pompano Beach, FL 33062</div>',
    },
    {
        title: 'Email',
        content:
            '<a href="mailto:info@ShopWreckParkPompano.org" class="contact-individual-content">info@ShopWreckParkPompano.org</a>',
    },
    {
        title: 'Donations & Mailing Address',
        content:
            '<div className="contact-individual-content">100 W. Atlantic Blvd. Fourth Floor <br /> Pompano Beach, Fl 33060</div>',
    },
];
const NewsContactUsSection = () => {
    return (
        <div id="contact-us">
            <div className="news-contact-us">
                <div className="contact-us-title">Contact Us</div>
                {/* Content */}
                <div className="contact-us-content">
                    {contactUsData?.map(({ title, content }, index) => {
                        return (
                            <div className="contact-individual-section" key={index}>
                                <div className="contact-individual-title">{title}</div>
                                <div dangerouslySetInnerHTML={{ __html: content }}></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default NewsContactUsSection;
