import React from 'react';
import { NewsContactUsSection, NewsHeroSection, NewsHistorySection, NewsTeamSection } from '../../components';
import './otherCSS/News.css';

const News = () => {
    return (
        <div className="news-page">
            {/* News Hero Section */}
            <NewsHeroSection />
            {/* Teams Section */}
            {/* <NewsTeamSection /> */}
            {/* History Section*/}
            <NewsHistorySection />
            {/* Contact Us Section */}
            <NewsContactUsSection />
        </div>
    );
};

export default News;
