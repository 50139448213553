import React, { useState } from 'react';
import StyledButton from './StyledButton';
import useCurrentBreakpoint from '../hooks/useCurrentBreakpoints';
import '../pages/other/otherCSS/News.css';

const NewsHeroSection = () => {
    const newsHeroContent = [
        {
            image: '/images/news/field-trip.png',
            description:
                'One of Wahoo Bay’s major goals is to introduce school aged children to the wonders of our oceans through organized, curated field experiences. These short expeditions will not only allow for an immersive experience at Wahoo Bay but also include short lessons on a variety of related topics. <br/><br/>The first public school field experience occurred on May 28, 2024. A group of energetic 3rd and 4th graders from Pompano Beach Elementary School got an up close and personal look at the SEAHIVES and the variety of fish life inhabiting this micro marine sanctuary.',
            mobileDescription:
                ' The first public school field experience occurred on May 28, 2024. A group of energetic 3rd and 4th graders from Pompano Beach Elementary School got an up close and personal look at the SEAHIVES and the variety of fish life inhabiting this micro marine sanctuary.',
            video: 'https://youtube.com/embed/eYa-W9QpDCY?si=T5yTPjXwl2Ek2BVm',
        },
        {
            image: '/images/news/sea-hive.png',
            description:
                'Coastal communities, like ours, depend on natural barriers, such as, mangroves and coral reefs, to help protect the shoreline and limit the impacts of flooding. The cost-effective, environmentally friendly concrete <a href="https://www.coe.miami.edu/research/seahive/" style="text-decoration: underline; color: black; font-weight: bold;">SEAHIVE</a>™ structures you see along the seawall protect our shoreline by dispersing wave energy and limiting flooding impacts.',
            video: 'https://www.youtube.com/embed/XriFqBgAA04?si=he4OBBBWnkY92LQt',
        },
        {
            image: '/images/news/drone.png',
            description:
                "In the shallow, protected waters of Florida's Pompano Beach Hillsboro inlet, a unique habitat restoration project called Wahoo Bay has come to fruition. Wahoo Bay started out as a conversation in the summer of 2106 and became reality in May of 2024.",
            video: 'https://www.youtube.com/embed/VkOJ2QBts84?si=f9C_fu17oS47Fw31',
        },
    ];

    const breakpoints = useCurrentBreakpoint();
    const [play, setPlay] = useState([false, false, false]);

    return (
        <div className="news-hero-section">
            <div className="left-side">
                <h1 className="news-title">News</h1>
                <div className="left-content">
                    <div style={{ marginBottom: '1rem' }}>
                        Wahoo Bay aims to raise awareness of the importance of keeping our oceans and reefs healthy
                        while providing a unique, immersive educational experience both in the water and online.
                    </div>
                    <div className="ambassador-content">
                        Learn more and help us protect this incredible space by becoming a{' '}
                        <a
                            href="https://tinyurl.com/WahooBayVolunteerGuide"
                            style={{ fontWeight: 'bold', color: 'black', textDecoration: 'underline' }}>
                            Wahoo Bay Ambassador
                        </a>{' '}
                        today!
                    </div>
                </div>
            </div>
            <div className="right-side">
                <div className="news-content">
                    {newsHeroContent.map((news, index) => {
                        let desc = news?.description;
                        let marginFirst = null;
                        if ((breakpoints?.isSmall || breakpoints?.isMedium) && news?.mobileDescription) {
                            desc = news?.mobileDescription;
                            marginFirst = '1rem';
                        }
                        // Updates the play array with this one if this element gets clicked
                        let tempPlay = { ...play };
                        tempPlay[index] = !play[index];
                        const newPlay = tempPlay;
                        return (
                            <div
                                className="news-element"
                                key={index}
                                style={{ marginLeft: marginFirst && marginFirst }}>
                                <div onClick={(e) => setPlay(newPlay)} style={{ position: 'relative' }}>
                                    {!play[index] ? (
                                        <img src={news?.image} alt="cover" className="news-images" />
                                    ) : (
                                        <iframe
                                            className="news-video"
                                            title="YouTube video player"
                                            src={news.video}
                                            allowFullScreen
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        />
                                    )}
                                    {/* Overlay Play Icon */}
                                    {!play[index] && news?.video && (
                                        <img
                                            className="grow"
                                            src="/icons/white-circle-play.png"
                                            alt="play-button"
                                            width={80}
                                            height={80}
                                            style={{
                                                zIndex: 1,
                                                position: 'absolute',
                                                color: 'white',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        />
                                    )}
                                </div>
                                <div
                                    className="news-description"
                                    dangerouslySetInnerHTML={{
                                        __html: desc,
                                    }}></div>
                            </div>
                        );
                    })}
                </div>
                {/* Button(s) */}
                <StyledButton textColor="black" title="Social Media" url="#footer" />
            </div>
        </div>
    );
};

export default NewsHeroSection;
