import React from 'react';
import { BayMap } from '../components';

const Map = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'white' }}>
            <BayMap />
        </div>
    );
};

export default Map;
