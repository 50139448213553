import React, { useCallback, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import AutoPlay from 'embla-carousel-autoplay';
import './ComponentsCSS/NewsHistory.css';
import useCurrentBreakpoint from '../hooks/useCurrentBreakpoints';

const NewsHistorySection = () => {
    const [emblaRef, emblaApi] = useEmblaCarousel({}, [AutoPlay({ playOnInit: true })]);
    const currentBreakpoint = useCurrentBreakpoint();
    const HISTORICAL_CAROUSEL_SLIDE_DATA = [
        { image: 'hist1.png' },
        { image: 'hist2.png' },
        { image: 'hist3.jpg' },
        { image: 'hist4.jpg' },
        // {
        //     video: 'https://docs.google.com/presentation/d/e/2PACX-1vQZ4eSjMasswuSnwZgptOhyZNMFmO-_3KYXKot02uYrAKZjYpxYNkxdwp5YM0fzgtfZwKiDihVQojMS/embed?start=true&loop=false&delayms=3000"',
        // },
    ];

    const toggleAutoplay = useCallback(() => {
        const autoplay = emblaApi?.plugins()?.autoplay;
        if (!autoplay) return;

        const playOrStop = autoplay.isPlaying() ? autoplay.stop : autoplay.play;
        playOrStop();
    }, [emblaApi]);

    useEffect(() => {
        if (!emblaApi) return;

        if (currentBreakpoint?.name === 'sm') {
            toggleAutoplay();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBreakpoint]);

    return (
        <div className="news-history-section">
            <div className="historical-title">Historical Look</div>
            {/* Carousel */}
            <div className="news-carousel">
                <div className="embla" ref={emblaRef}>
                    <div className="news-carousel-container">
                        {HISTORICAL_CAROUSEL_SLIDE_DATA.map(({ image, video }, index) => (
                            <div className="news-carousel-image-container" key={index}>
                                {video ? (
                                    <iframe
                                        title="historical look"
                                        className="news-carousel-image"
                                        src={video}
                                        frameborder="0"
                                        allowfullscreen="true"
                                        mozallowfullscreen="true"
                                        webkitallowfullscreen="true"></iframe>
                                ) : (
                                    <img
                                        className="news-carousel-image"
                                        src={`/images/historical-look/${image}`}
                                        alt="historical look"
                                        key={index}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsHistorySection;
