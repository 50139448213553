import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// All layouts/containers
import HorizontalLayout from '../layouts/Horizontal';
import Map from '../pages/Map';
import Category from '../pages/other/Category';
import NotFound from '../pages/NotFound';
import Dashpage from '../pages/Dashpage';
import News from '../pages/other/News';

// - other
const FAQ = React.lazy(() => import('../pages/other/FAQ')); // NOT UPDATED
const Education = React.lazy(() => import('../pages/other/Education'));
const Course = React.lazy(() => import('../pages/other/Course'));
const FishTails = React.lazy(() => import('../pages/other/FishTails')); // NOT UPDATED
const Fishial = React.lazy(() => import('../pages/other/Fishial'));
const Main = React.lazy(() => import('../pages/other/Main'));

const loading = () => <div className=""></div>;

const LoadComponent = ({ component: Component }) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    let Layout = HorizontalLayout;

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [
                {
                    path: '*',
                    element: <LoadComponent component={NotFound} />,
                },
                {
                    path: 'pages',
                    children: [
                        {
                            path: '*',
                            element: <LoadComponent component={NotFound} />,
                        },
                        {
                            path: 'news',
                            element: <LoadComponent component={News} />,
                        },
                        {
                            path: 'education',
                            children: [
                                {
                                    path: '',
                                    element: <LoadComponent component={Education} />,
                                },
                                {
                                    path: ':id',
                                    element: <LoadComponent component={Category} />,
                                },
                            ],
                        },
                        {
                            path: 'course',
                            element: <LoadComponent component={Course} />,
                        },
                        {
                            path: 'fishtails',
                            element: <LoadComponent component={FishTails} />,
                        },
                        {
                            path: 'dashpage',
                            element: <LoadComponent component={Dashpage} />,
                        },
                        {
                            path: 'contact-us',
                            element: <LoadComponent component={News} />,
                        },
                        {
                            path: 'ai',
                            element: <LoadComponent component={Fishial} />,
                        },
                        {
                            path: 'map',
                            element: <LoadComponent component={Map} />,
                        },
                        {
                            path: 'faq',
                            element: <LoadComponent component={FAQ} />,
                        },
                        {
                            path: 'main',
                            element: <LoadComponent component={Main} />,
                        },
                        {
                            path: 'courses',
                            children: [
                                {
                                    path: ':id',
                                    element: <LoadComponent component={Course} />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };
