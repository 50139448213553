// @flow
import React from 'react';
import Routes from './routes/Routes';

// setup fake backend
import { configureFakeBackend } from './helpers';

// For Saas import Saas.scss
import './assets/scss/Creative.scss';

// configure fake backend
configureFakeBackend();

/**
 * Main app component
 */
const App = () => {
    return <Routes></Routes>;
};

export default App;
