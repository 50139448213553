// @flow
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { logos } from './footer-logos';
import darkImage from '../assets/images/wb-logo-dark.png';
import './styles/Footer.css';

// images
const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer" id="footer">
            <div className="container-fluid">
                <div>
                    <Row>
                        <Col
                            xs={12}
                            xl={4}
                            className="footer-links"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                // justifyContent: 'center',
                            }}>
                            <div className="footer-container">
                                <div className="footer-content">
                                    {/* Image */}
                                    <img src={darkImage} height="100" alt="Wahoo Bay" style={{ marginBottom: 1 }} />
                                    
                                    {/* Links below the image */}
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 6 }}>
                                        <Link to="/pages/news#top" style={{ marginBottom: 2, color: 'black' }}>
                                            About Us
                                        </Link>
                                        <Link to="/pages/news#contact-us" style={{ color: 'black' }}>
                                            Contact Us
                                        </Link>
                                    </div>
                                    <div className="small-container">
                                        <div style={{ display: 'flex' }}>
                                            <a
                                                href="https://www.facebook.com/wahoobaypompano/"
                                                target="_blank"
                                                rel="noreferrer">
                                                <img
                                                    style={{ marginRight: 8 }}
                                                    src="/icons/fb-dark.png"
                                                    alt="facebook"
                                                    width="30px"
                                                />
                                            </a>
                                            <a
                                                href="https://www.instagram.com/wahoobaypompano/"
                                                target="_blank"
                                                rel="noreferrer">
                                                <img src="/icons/ig-dark.png" alt="instagram" width="30px" />
                                            </a>
                                        </div>
                                        <p className="copyright" style={{ color: 'black', marginTop: 12 }}>
                                            &copy; {currentYear} Wahoo Bay by Shipwreck Park, Inc.
                                        </p>
                                    </div>
                                </div>
                                <div className="big-container">
                                    <div style={{ display: 'flex' }}>
                                        <a
                                            href="https://www.facebook.com/wahoobaypompano/"
                                            target="_blank"
                                            rel="noreferrer">
                                            <img
                                                style={{ marginRight: 8 }}
                                                src="/icons/fb-dark.png"
                                                alt="facebook"
                                                width="30px"
                                            />
                                        </a>
                                        <a
                                            href="https://www.instagram.com/wahoobaypompano/"
                                            target="_blank"
                                            rel="noreferrer">
                                            <img src="/icons/ig-dark.png" alt="instagram" width="30px" />
                                        </a>
                                    </div>
                                    <p className="copyright" style={{ color: 'black', marginTop: 12 }}>
                                        &copy; {currentYear} Wahoo Bay by Shipwreck Park, Inc.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="footer-links align-items-end">
                            <Row>
                                <Col style={{ color: 'black' }} className="d-flex justify-content-center">
                                    Thanks to our sponsors and partners:
                                </Col>
                            </Row>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                {logos?.map((logo, index) => {
                                    let isFau = logo?.name?.includes('FAU');
                                    return (
                                        <div
                                            md="auto"
                                            className={`footer-links logo-container${isFau ? '-fau' : ''}`}
                                            key={index}
                                            style={{ width: isFau && '100%' }}>
                                            <a href={logo?.url} target="_blank" rel="noreferrer">
                                                <img
                                                    className={`logo-image${isFau ? '-fau' : ''}`}
                                                    src={logo?.src}
                                                    alt={logo?.name}
                                                />
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        </Col>
                    </Row>
                    <Row></Row>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
