import React from 'react';
import StyledButton from '../components/StyledButton';
const NotFound = () => {
    return (
        <div
            style={{
                backgroundColor: 'white',
                color: 'black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '0rem',
                paddingTop: '10rem',
                paddingBottom: '8rem',
            }}>
            <h1>404 Not Found</h1>
            <p>The page you are looking for does not exist</p>
            <div>
                <StyledButton
                    marginTop="0.2rem"
                    backgroundColor="#505A63"
                    url="/"
                    title="Home"
                    fontSize="14px"
                    marginRight="10px"
                />
                <StyledButton marginTop="0.2rem" url="/pages/education" title="Education" fontSize="14px" />
            </div>
        </div>
    );
};

export default NotFound;
