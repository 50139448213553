import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';
import { EducationImageCard } from '../../components';
import { kebabCase } from 'lodash';
import './otherCSS/Course.css';
import '../other/otherCSS/Education.css';

const Category = () => {
    const [title, setTitle] = useState('');
    const [courses, setCourses] = useState('');
    const params = useParams();
    useEffect(() => {
        setTitle(params?.id);
        setCourses(find(allCourses, { name: params?.id }));
        // eslint-disable-next-line
    }, []);

    const allCourses = [
        {
            name: 'Educator Resources',
            content: ['Field Based Activities', 'Traveling Trunk Program', 'Field Experience Guide'],
        },
        {
            name: 'Art & Science',
            content: [
                'Teaching Climate Change Through Art S. Fl. Habitats',
                'Teaching Climate Change Through Art Wahoo Bay',
            ],
        },
        {
            name: 'Collegiate',
            content: [],
        },
        {
            name: 'Prek',
            title: 'PreK - 3rd Grade',
            content: ['Crustacean Creations', 'The Great Plankton Race', 'Build A Backbone'],
        },
        {
            name: 'Fourth',
            title: '4th - 8th Grade',
            content: ['Mangrove Trees', 'Coral Reefs', 'Intertidal Zone', 'The Great Plankton Race'],
        },
        {
            name: 'Ninth',
            title: '9th - 12th Grade',
            content: ['Mangrove Trees', 'Coral Reefs', 'Intertidal Zone', 'The Great Plankton Race'],
        },
    ];

    return (
        <div className="course-page">
            <h1 className="course-title">{courses?.title ? courses?.title : title}</h1>
            {/* Courses */}
            <div className="courses-container">
                {courses?.content?.map((course, index) => (
                    <EducationImageCard
                        key={index}
                        index={index}
                        type="sm"
                        margin="1rem"
                        border
                        borderRadius="10px"
                        href={`/pages/courses/${kebabCase(course)}`}
                        src={`/images/courses/${course}.png`}
                        alt={course}
                    />
                ))}
            </div>
        </div>
    );
};

export default Category;
