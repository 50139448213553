import { capitalize } from 'lodash';
import React, { useState, useEffect } from 'react';
import StyledButton from '../components/StyledButton';

const Dashpage = () => {
    const [view, setView] = useState('Air');
    useEffect(() => {
        const hash = window.location.hash;
        const noHash = hash.split('#');
        setView(capitalize(noHash[1]));
    }, []);
    useEffect(() => {
        if (view !== 'Air' && view !== 'Water') setView('Air');
    }, [view]);

    const viewToggle = () => {
        setView(view === 'Air' ? 'Water' : 'Air');
    };
    return (
        <div style={{ paddingTop: '3.8rem' }}>
            <div style={{ margin: '1rem' }} onClick={(e) => viewToggle()}>
                <StyledButton
                    title={view === 'Water' ? 'Air Sensor' : 'Water Sensor'}
                    backgroundColor="#30549c"
                    marginTop="0px"
                />
            </div>
            <div style={{ height: '92vh' }}>
                {view === 'Water' ? (
                    <iframe
                        title="Grafana Daashboard"
                        src="https://grafana.wahoobay.net/d/fdruz46pp5jpce/water-dashboard?orgId=1&refresh=1h&theme=light"
                        width="100%"
                        height="100%"></iframe>
                ) : (
                    <iframe
                        title="Grafana Daashboard"
                        src="https://grafana.wahoobay.net/d/fdrlbyd6ay1vkf/air-dashboard?orgId=1&refresh=1h&theme=light"
                        width="100%"
                        height="100%"></iframe>
                )}
            </div>
        </div>
    );
};

export default Dashpage;
