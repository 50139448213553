export const logos = [
    {
        name: 'Broward County',
        url: 'https://www.broward.org/Pages/Welcome.aspx',
        src: '/images/logos/broward-county-fl.png',
    },
    {
        name: 'Chamber of Commerce',
        url: 'https://www.pompanobeachchamber.com/',
        src: '/images/logos/chamber-of-commerce.png',
    },
    {
        name: 'Chaos Made',
        url: 'https://www.fau.edu/engineering/',
        src: '/images/logos/chaos-made.png',
    },
    {
        name: 'GHA',
        url: 'https://www.gha-associates.com/',
        src: '/images/logos/gha.png',
    },
    {
        name: 'Keith and Assoc.',
        url: 'https://keithteam.com/',
        src: '/images/logos/keith-dark.jpg',
    },
    {
        name: 'City of Pompano Beach',
        url: 'https://www.pompanobeachfl.gov/',
        src: '/images/logos/pompano-beach.png',
    },
    {
        name: 'SeaRise',
        url: 'https://www.anecdata.org/projects/view/182',
        src: '/images/logos/searise.png',
    },
    {
        name: 'S. Florida Diving',
        url: 'https://www.southfloridadiving.com/',
        src: '/images/logos/florida-diving.png',
    },
    {
        name: 'Coastal Eco Group ',
        url: 'http://www.coastaleco-group.com/',
        src: '/images/logos/coastal-eco-group.png',
    },
    {
        name: 'University of Miami College of Engineering',
        url: 'https://www.coe.miami.edu/',
        src: '/images/logos/um-coe.png',
    },
    {
        name: 'Qualmann',
        url: 'http://www.qualmannmarine.com/',
        src: '/images/logos/qualmann.png',
    },
    {
        name: 'Fishial',
        url: 'https://www.fishial.ai/',
        src: '/images/logos/fishial-dark.png',
    },
    {
        name: 'View into the blue',
        url: 'https://www.viewintotheblue.com/',
        src: '/images/logos/view-into-the-blue-dark.png',
    },
    {
        name: 'FAU College of Electrical Engineering and Computer Science',
        url: 'https://www.fau.edu/engineering/',
        src: '/images/logos/fau.png',
    },
    {
        name: 'FAU Center for Connected Autonomy and Artificial Intelligence',
        url: 'https://www.fau.edu/engineering/research/c2a2/',
        src: '/images/logos/caai.png',
    },
];
