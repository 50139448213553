import React from 'react';
import './ComponentsCSS/EducationImageCard.css';

const EducationImageCard = (props) => {
    const { alt, border, borderRadius, href, index, src, type, margin } = props;
    return (
        <>
            {href ? (
                // for now this will always be the sm one
                <a href={href} key={index} className="image-card-container">
                    <img
                        className={`image-card${type === 'sm' ? '-sm' : ''} slide`}
                        src={src}
                        alt={alt}
                        style={{
                            marginLeft: margin,
                            border: border && '1px solid #AAB9C5',
                            borderRadius: borderRadius,
                        }}
                    />
                </a>
            ) : (
                // for now this will always be the big one
                <img
                    className={`image-card${type === 'sm' ? '-sm' : ''}`}
                    src={src}
                    alt={alt}
                    style={{
                        marginLeft: index !== 0 && margin,
                        marginRight: index === 3 && margin,
                        marginTop: index >= 3 && margin,
                        border: border && '1px solid #AAB9C5',
                        borderRadius: borderRadius,
                    }}
                />
            )}
        </>
    );
};

export default EducationImageCard;
