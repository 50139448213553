// @flow
import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Topbar from '../Topbar';
import Footer from '../Footer';
import { getTitleFromParams } from '../../utils/getTitleFromParams';
import getTitleFromPathname from '../../utils/getTitleFromPathname';

const loading = () => <div className="text-center"></div>;

const getMetaTitle = (title) => {
    // UPDATE META TITLE HERE
    switch (title) {
        case 'Main':
            return 'Wahoo Bay Educational Marine Park';
        case 'Map':
            return `Map | Wahoo Bay Educational Marine Park`;
        case 'Dashpage':
            return `Dashboard | Wahoo Bay Educational Marine Park`;
        case 'News':
            return 'News | Wahoo Bay Educational Marine Park';
        case 'Ai':
            return `AI | Wahoo Bay Educational Marine Park`;
        case 'Teacher Resources':
            return 'Teacher Resources | Wahoo Bay Educational Marine Park';
        case 'Education':
            return 'Education | Wahoo Bay Educational Marine Park';
        case 'Faq':
            return 'FAQs | Wahoo Bay Educational Marine Park';
        default:
            return `${title} Course | Wahoo Bay Educational Marine Park`;
    }
};

const getMetaDescription = (title) => {
    // UPDATE META DESCRIPTION HERE
    switch (title) {
        case 'Main':
            return 'Wahoo Bay is dedicated to restoring the natural habitat thorough a combnation of Art, Conservation, and Education - ACE';
        case 'Map':
            return `Check out Wahoo Bay's Sensors on our map`;
        case 'Dashpage':
            return `Check out data from our sensors in Pompano Beach`;
        case 'News':
            return `Read up on recent Updates about Wahoo Bay and it's team and history`;
        case 'Ai':
            return `Wahoo Bay trained it's own model that identifies the fish in it's underwater feed`;
        case 'Teacher Resources':
            return 'Wahoo Bay has resources for educators that want to teach their kids about marine biology and the environment';
        case 'Education':
            return 'Wahoo Bay has courses for kids of all ages that educators and parents can access to better teach them';
        case 'FAQ':
            return 'Frequently asked questions about Wahoo Bay';
        default:
            return 'Wahoo Bay Educational Marine Park Course';
    }
};

const HorizontalLayout = () => {
    const url = window.location.pathname;
    const params = useParams();
    const title = getTitleFromParams(params) || getTitleFromPathname(window.location.pathname);
    const image = `/images/metadata/${title}.png`;
    return (
        <>
            <Helmet>
                <title>{getMetaTitle(title)}</title>
                <meta name="description" content={getMetaDescription(title)} />
                <meta property="og:image" content={image} key="og:image" />
                <meta name="twitter:image" content={image} />
            </Helmet>
            <div>
                <Suspense fallback={loading()}>
                    {/* CHANGE NAVBAR BACKGROUND HERE */}
                    {/* style="transparent"  */}
                    <Topbar style={url === '/pages/main' && 'transparent'} />
                    <div>
                        <Outlet />
                    </div>
                    <Footer />
                </Suspense>
            </div>
        </>
    );
};

export default HorizontalLayout;
