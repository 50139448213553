import React from 'react';
import './ComponentsCSS/StyledButton.css';

const StyledButton = ({
    title = 'Title',
    url,
    rel,
    target,
    backgroundColor = '#1AA6F1',
    textColor,
    fontSize = 16,
    marginTop = '1rem',
    marginRight = '0rem',
}) => {
    return (
        <button
            className="plain-btn grow"
            style={{
                width: 'fit-content',
                padding: '0.5rem 1.25rem',
                backgroundColor: backgroundColor,
                marginTop: marginTop,
                marginRight: marginRight,
                fontSize: '20px',
                fontWeight: 'bold',
            }}>
            <a
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: textColor || 'white',
                    fontSize: fontSize,
                }}
                href={url}
                rel={rel}
                target={target}>
                {title}
            </a>
        </button>
    );
};

export default StyledButton;
